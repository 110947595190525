// 1：文本，2：图片，3：视频，4：语音消息，5：gif，6：文档，7：位置，8：联系人名片，9：网格图，10：贴图，11：音频消息, 12：一次性的图片或者视频
export const chatType = {
  text: 1,
  image: 2,
  video: 3,
  voice: 4,
  gif: 5,
  file: 6,
  location: 7,
  card: 8,
  gridChart: 9,
  sticker: 10,
  audio: 11,
  onceImageOrVideo: 12,
};

// 标识消息是哪一方发送的
// 1：我方发送
// 2：对方发送
// 3：系统消息
export const sendSide = {
  ourSide: 1,
  otherSide: 2,
  systemSide: 3,
};

// tab1 会话列表demo数据
export const contactDemoRes = {
  data: {
    code: 200,
    message: "OK",
    data: {
      total: 3,
      length: 10,
      chat_sessions: [
        {
          title: "Pre holidayers",
          chat_type: 2,
          last_message: "Hi everyone, how will we spend the holiday next week?",
          last_time: 1663215278,
          chat_session_id: "group",
          avatar_url: require("../../../assets/chat/demo_group.svg"),
          unique_id: "group",
        },
        {
          title: "Luke Adler",
          chat_type: 1,
          last_message: "Hey man",
          last_time: 1663118040,
          chat_session_id: "man",
          avatar_url: require("../../../assets/chat/demo_man.svg"),
          unique_id: "man",
        },
        {
          title: "Hailey Levine",
          chat_type: 1,
          last_message: "Wanna go to the party tonight?",
          last_time: 1662812280,
          chat_session_id: "woman",
          avatar_url: require("../../../assets/chat/demo_woman.svg"),
          unique_id: "woman",
        },

        {
          title: "Claire",
          chat_type: 1,
          last_message: "Hey man",
          last_time: 1663118040,
          chat_session_id: "man",
          avatar_url: require("../../../assets/chat/group_woman1.svg"),
          unique_id: "man2",
        },
        {
          title: "Ariana Harris",
          chat_type: 1,
          last_message: "Wanna go to the party tonight?",
          last_time: 1662812280,
          chat_session_id: "woman",
          avatar_url: require("../../../assets/chat/group_woman2.svg"),
          unique_id: "woman2",
        },

        {
          title: "Andrew Smith",
          chat_type: 1,
          last_message: "Hey man",
          last_time: 1663118040,
          chat_session_id: "man",
          avatar_url: require("../../../assets/chat/demo_man1.svg"),
          unique_id: "man3",
        },
        {
          title: "Liam Jordan",
          chat_type: 1,
          last_message: "Wanna go to the party tonight?",
          last_time: 1662812280,
          chat_session_id: "woman",
          avatar_url: require("../../../assets/chat/demo_woman1.svg"),
          unique_id: "woman3",
        },
        {
          title: "Blake Mattews",
          chat_type: 1,
          last_message: "Hey man",
          last_time: 1663118040,
          chat_session_id: "man",
          avatar_url: require("../../../assets/chat/demo_woman2.svg"),
          unique_id: "man4",
        },
        {
          title: "Cameron",
          chat_type: 1,
          last_message: "Wanna go to the party tonight?",
          last_time: 1662812280,
          chat_session_id: "woman",
          avatar_url: require("../../../assets/chat/demo_woman3.svg"),
          unique_id: "woman4",
        },
        {
          title: "Nelia Justo",
          chat_type: 1,
          last_message: "Hey man",
          last_time: 1663118040,
          chat_session_id: "man",
          avatar_url: require("../../../assets/chat/demo_woman4.svg"),
          unique_id: "man5",
        },
        {
          title: "Aden Moore",
          chat_type: 1,
          last_message: "Wanna go to the party tonight?",
          last_time: 1662812280,
          chat_session_id: "woman",
          avatar_url: require("../../../assets/chat/demo_woman5.svg"),
          unique_id: "woman5",
        },
        {
          title: "Axel",
          chat_type: 1,
          last_message: "Hey man",
          last_time: 1663118040,
          chat_session_id: "man",
          avatar_url: require("../../../assets/chat/demo_woman6.svg"),
          unique_id: "man6",
        },
      ],
    },
  },
};

// tab2 通讯录列表demo数据
export const mailDemoRes = {
  data: {
    code: 200,
    message: "OK",
    data: {
      length: 32,
      contacts: [
        {
          label: "A",
          children: [
            {
              id: "2c4a29b9-6f32-4bbe-b8e1-ecc0353bd97f",
              name: "Ademo",
              email: "Rodolfo.Parker7@hotmail.com",
              phone: "376-403-7602",
            },
            {
              id: "18580acf-99f1-4a94-9d73-d4717583afd6",
              name: "Adams",
              email: "Lela_Erdman28@gmail.com",
              phone: "889-712-9454",
            },
          ],
        },
        {
          label: "B",
          children: [
            {
              id: "6ef72477-d474-4ef3-8953-da50ea42ba5c",
              name: "Bahringer",
              email: "Eleanore.Leannon11@hotmail.com",
              phone: "863-769-9014",
            },
            {
              id: "989c6270-725f-4fd8-856e-4fce6d427553",
              name: "Bailey",
              email: "Bradford_Gislason@yahoo.com",
              phone: "355-807-4362",
            },

            {
              id: "dc48f099-bcc2-4684-9f23-3137b02be26f",
              name: "Bruen",
              email: "Hazel.Paucek7@hotmail.com",
              phone: "481-974-7584",
            },
          ],
        },
        {
          label: "C",
          children: [
            {
              id: "6e2c55db-c0e2-4a64-bdbd-b6303f741dbd",
              name: "Cruickshank",
              email: "Deon46@hotmail.com",
              phone: "370-467-0638",
            },
            {
              id: "c363b449-d99d-4be3-a811-44f26cd6379c",
              name: "Cummings",
              email: "Arlo_McClure@hotmail.com",
              phone: "323-521-4922",
            },
          ],
        },
        {
          label: "D",
          children: [
            {
              id: "d5719aa5-2400-4272-a5cd-6c9d85d86c49",
              name: "Dare",
              email: "Kelsi22@gmail.com",
              phone: "909-826-1623",
            },

            {
              id: "59c86d80-22a3-4a85-8b0c-b1b92a0856c7",
              name: "Donnelly",
              email: "Erwin.Schiller@gmail.com",
              phone: "464-870-9153",
            },
            {
              id: "c0f61652-98dc-4360-a648-b4dba5fa4825",
              name: "Doyle",
              email: "Kellen28@hotmail.com",
              phone: "342-228-4973",
            },
            {
              id: "43372db8-820d-4716-a035-f8c6ce68628e",
              name: "Durgan",
              email: "Myrtie41@hotmail.com",
              phone: "897-522-3518",
            },
          ],
        },
        {
          label: "E",
          children: [
            {
              id: "522aa7ae-3eff-44f9-b866-1597ccf0be5c",
              name: "Ebert",
              email: "Raphaelle76@gmail.com",
              phone: "934-462-3697",
            },
            {
              id: "255dd10c-6ae2-4f20-98a9-416d40f6bc05",
              name: "Emard",
              email: "Loma95@gmail.com",
              phone: "931-442-5488",
            },
            {
              id: "aa3278d5-75b0-4121-9c14-7a5c4773bcbe",
              name: "Erdman",
              email: "Waldo.Gleichner@hotmail.com",
              phone: "751-879-4802",
            },
          ],
        },
        {
          label: "V",
          children: [
            {
              id: "1d6b7588-48bd-4cd1-a348-86749dc41824",
              name: "VonRueden",
              email: "Kaleigh22@gmail.com",
              phone: "355-391-4600",
            },
            {
              id: "4aee6ad6-6f7c-41fb-bf69-f5ab40140718",
              name: "VonRueden",
              email: "Obie83@hotmail.com",
              phone: "974-458-4478",
            },
          ],
        },
        {
          label: "W",
          children: [
            {
              id: "3c9b79d3-35dd-4f1a-8c17-72b17c46f4d7",
              name: "Walker",
              email: "Kory.Fay4@gmail.com",
              phone: "536-311-2505",
            },

            {
              id: "b7e58077-40e8-4736-9092-57619ea76a3c",
              name: "Weber",
              email: "Verona.Durgan58@hotmail.com",
              phone: "355-613-4728",
            },
            {
              id: "70e5f8c3-b910-4f3e-9d91-698d19f83103",
              name: "Weissnat",
              email: "Ben_Boehm@yahoo.com",
              phone: "714-542-6583",
            },
            {
              id: "1170fb82-beae-4253-b204-c589556691e5",
              name: "Welch",
              email: "Marietta_Daniel@hotmail.com",
              phone: "711-653-3192",
            },
            {
              id: "15a6d8cf-ebe1-4bfd-8153-ca301f16b470",
              name: "West",
              email: "Dortha99@gmail.com",
              phone: "484-215-9213",
            },
            {
              id: "259de3ea-7206-4be6-84bd-e2fdf08323bd",
              name: "West",
              email: "Darion_Kassulke@hotmail.com",
              phone: "745-825-7959",
            },
            {
              id: "6a23c7ba-fce3-45ab-8c70-ac45bfebe66c",
              name: "White",
              email: "Savion_Kovacek67@yahoo.com",
              phone: "480-610-7554",
            },
            {
              id: "0bf11ad4-3078-4874-9f58-3785067ef8f7",
              name: "White",
              email: "Javon.Predovic@hotmail.com",
              phone: "200-555-4580",
            },
            {
              id: "03a8181b-deed-4bb8-8ab8-6b36ed38db80",
              name: "Wilkinson",
              email: "Kennedi46@gmail.com",
              phone: "838-765-7535",
            },
            {
              id: "bda8364e-822c-4f51-9c73-14be664417d4",
              name: "Will",
              email: "Cleora_Gutmann42@gmail.com",
              phone: "584-834-9867",
            },
            {
              id: "3613fbc1-f4ba-4f71-8469-3d96446b12be",
              name: "Will",
              email: "Valentina.Osinski85@yahoo.com",
              phone: "650-894-6056",
            },
            {
              id: "0f21f219-9a2c-43f6-8e9a-249e8f506ffa",
              name: "Willms",
              email: "Amanda_Zulauf@gmail.com",
              phone: "209-875-3543",
            },
            {
              id: "3c19923a-8ad1-4760-8b6a-08fd2205e880",
              name: "Willms",
              email: "Carolanne76@hotmail.com",
              phone: "855-289-5186",
            },
            {
              id: "a48a6d46-ae2c-4bee-884f-bd10672b8823",
              name: "Willms",
              email: "Vicenta_Cummings17@hotmail.com",
              phone: "744-712-2248",
            },
            {
              id: "f21ad6a9-79cf-433f-bf5c-3ccdf0bd120e",
              name: "Willms",
              email: "Armani.Ullrich@gmail.com",
              phone: "801-538-3497",
            },
            {
              id: "9fce9df4-7cd0-409a-a66f-d319956e853a",
              name: "Wunsch",
              email: "Hope.Schneider84@yahoo.com",
              phone: "999-699-1164",
            },
          ],
        },
      ],
    },
  },
};

// tab3: 通话记录列表
export const phoneDemoRes = {
  data: {
    code: 200,
    message: "OK",
    data: {
      total: 22,
      length: 15,
      calls: [
        {
          contact_name: "SDemon",
          date: 1662709931,
          status: "outgoing",
          count: 47,
        },
        {
          contact_name: "Lila",
          date: 1662709931,
          status: "no answer",
          count: 13,
        },
        {
          contact_name: "Titus",
          date: 1662709931,
          status: "outgoing",
          count: 36,
        },
        {
          contact_name: "Arjun",
          date: 1662709931,
          status: "incoming",
          count: 20,
        },
        {
          contact_name: "Nicola",
          date: 1662709931,
          status: "missed",
          count: 36,
        },
        {
          contact_name: "Cormier",
          date: 1662709931,
          status: "no answer",
          count: 13,
        },
        {
          contact_name: "Juana",
          date: 1662709931,
          status: "incoming",
          count: 17,
        },
        {
          contact_name: "Streich",
          date: 1662709931,
          status: "outgoing",
          count: 13,
        },
        {
          contact_name: "Fatima",
          date: 1662709931,
          status: "outgoing",
          count: 47,
        },
        {
          contact_name: "Cristoph",
          date: 1662709931,
          status: "outgoing",
          count: 20,
        },
        {
          contact_name: "Guadalupe_Willms2",
          date: 1662709931,
          status: "outgoing",
          count: 36,
        },
        {
          contact_name: "Donne",
          date: 1662709931,
          status: "outgoing",
          count: 17,
        },
        {
          contact_name: "Keega",
          date: 1662709931,
          status: "outgoing",
          count: 13,
        },
        {
          contact_name: "Dawson",
          date: 1662709931,
          status: "outgoing",
          count: 17,
        },
        {
          contact_name: "Branson",
          date: 1662709931,
          status: "outgoing",
          count: 13,
        },
      ],
    },
  },
};

// rightContent
export const chatContentList = {
  group: [
    {
      send_side: 1,
      type: 1,
      content: "Hi everyone, how will we spend the holiday next week?",
      date: 1663215278,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Alex",
      type: 1,
      content: "idk. Maybe CA?",
      date: 1663215278,
      avatar_url: require("../../../assets/chat/group_woman1.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content: "I have a friend there and he sent me a video about CA.",
      date: 1663215278,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 1,
      type: 3,
      // content: "[video]",
      date: 1663215278,
      oss_key: "demo/e89357b0-49e1-11ed-aec7-00163e010ccd.mp4",
      file_name: "video.mp4",
      size: 8423615,
      file_url:
        "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2Fe89357b0-49e1-11ed-aec7-00163e010ccd.mp4?demo=1",
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Jules",
      type: 1,
      content: "Whoa. I have to say this is the very time to go to LA.😀",
      date: 1663215318,
      avatar_url: require("../../../assets/chat/group_woman2.svg"),
    },
    {
      send_side: 2,
      sender: "Jules",
      type: 1,
      content: "The sunshine, palm tree and beach. I hope I can go there.",
      date: 1663215368,
      avatar_url: require("../../../assets/chat/group_woman2.svg"),
    },
    {
      send_side: 2,
      sender: "Alex",
      type: 1,
      content: "Then come with us.",
      date: 1663215438,
      avatar_url: require("../../../assets/chat/group_woman1.svg"),
    },
    {
      send_side: 2,
      sender: "Jules",
      type: 1,
      content: "Nah. Money's tight. My budget is done for the month.😭",
      date: 1663215488,
      avatar_url: require("../../../assets/chat/group_woman2.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content:
        "There are many famous winery and golf resorts. I have been there like 7 or 8 years ago.",
      date: 1663215548,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 1,
      type: 2,
      // content: "[picture]",
      date: 1663215608,
      oss_key: "demo/4c17602a-49e5-11ed-aec7-00163e010ccd.jpeg",
      file_name: "image.jpeg",
      size: 3907791,
      file_url:
        "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2F4c17602a-49e5-11ed-aec7-00163e010ccd.jpeg?demo=1",
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Alex",
      type: 1,
      content: "Deal then！",
      date: 1663215668,
      avatar_url: require("../../../assets/chat/group_woman1.svg"),
    },

    {
      send_side: 3,
      type: 1,
      content: "2022-09-16 09:05",
      date: 1663215668,
    },
    {
      send_side: 1,
      type: 1,
      content: "Have you booked the ticket?",
      date: 1663290300,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Alex",
      type: 1,
      content: "Ooops. It slipped my mind. Wait a sec. I will book now.",
      date: 1663290360,
      avatar_url: require("../../../assets/chat/group_woman1.svg"),
    },
    {
      send_side: 3,
      type: 1,
      content: "2022-09-16 09:10",
      date: 1663290600,
    },
    {
      send_side: 2,
      sender: "Alex",
      type: 1,
      content: "Got it.",
      date: 1663290600,
      avatar_url: require("../../../assets/chat/group_woman1.svg"),
    },

    /* {
    send_side: 1,
    type: 10,
    content: "[picture]",
    date: 1663215278,
    oss_key: "http://ole.biz",
    file_name: "a video",
    size: 12344,
    file_url:
      "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2F7d21e0d9-3e09-11ed-bbc0-00163e010ccd.jpeg?demo=1",
  },
  {
    send_side: 1,
    type: 9,
    content: "[picture]",
    date: 1663215278,
    oss_key: "http://allie.name",
    file_name: "a grid image",
    size: 888,
    file_url:
      "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2Fb4dcabdb-3e09-11ed-bbc0-00163e010ccd.jpeg?demo=1",
  },
  {
    send_side: 2,
    sender: "xiaoliu",
    type: 8,
    content: "this is a bussiness card",
    date: 1663215278,
  },
  {
    send_side: 1,
    type: 7,
    content: "82092 Taurean Hill",
    date: 1663215278,
  },
  {
    send_side: 1,
    type: 6,
    content: "[other]",
    date: 1663215278,
    oss_key: "demo/3b82b3ee-3e0a-11ed-bbc0-00163e010ccd.doc",
    file_name: "doc file name",
    size: 23588,
    file_url:
      "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2F3b82b3ee-3e0a-11ed-bbc0-00163e010ccd.doc?demo=1",
  },
  {
    send_side: 2,
    sender: "xiaoliu",
    type: 4,
    content: "[audio]",
    date: 1663215278,
    oss_key: "demo/94eea498-3e0a-11ed-bbc0-00163e010ccd.mp3",
    file_name: "音频.mp3",
    size: 100005,
    file_url:
      "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2F94eea498-3e0a-11ed-bbc0-00163e010ccd.mp3?isDemo=1",
  },
  {
    send_side: 2,
    sender: "xiaoliu",
    type: 1,
    content: "这是一条消息2",
    date: 1663215278,
  },
  {
    send_side: 1,
    type: 3,
    content: "[video]",
    date: 1663215278,
    oss_key: "http://julianne.com",
    file_name: "a vedio",
    size: 10245,
    file_url:
      "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2Fc3a04203-3e06-11ed-bbc0-00163e010ccd.mp4?demo=1",
  },
  {
    send_side: 2,
    sender: "xiaoliu",
    type: 3,
    content: "[picture]",
    date: 1663215278,
    oss_key: "demo/108b2298-3e0b-11ed-bbc0-00163e010ccd.mp4",
    file_name: "a picture",
    size: 10245,
    file_url:
      "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2F108b2298-3e0b-11ed-bbc0-00163e010ccd.mp4?isDemo=1",
  },
  {
    send_side: 1,
    type: 1,
    content: "这是一条消息",
    date: 1663215278,
  }, */
  ],
  man: [
    {
      send_side: 2,
      sender: "Luke Adler",
      type: 1,
      content: "Hey man",
      date: 1663118040,
      avatar_url: require("../../../assets/chat/demo_man.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content: "Sup bro",
      date: 1663118100,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Luke Adler",
      type: 1,
      content: "I am good. The wedding was finished just now.",
      date: 1663118160,
      avatar_url: require("../../../assets/chat/demo_man.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content: "I am sorry for not being there.",
      date: 1663118220,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content: "i am honestly sorry",
      date: 1663118280,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Luke Adler",
      type: 1,
      content:
        "Man, no worry about it. I can't believe that I have a family now and I will be a father in the future.",
      date: 1663118340,
      avatar_url: require("../../../assets/chat/demo_man.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content:
        "Ah. Kids. My wife just sent me a shopping list preparing for Christmas.",
      date: 1663118400,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 1,
      type: 6,
      // content: "[other]",
      date: 1663118460,
      oss_key: "demo/3b82b3ee-3e0a-11ed-bbc0-00163e010ccd.doc",
      file_name: "shopping list for Xmas.doc",
      size: 23588,
      file_url:
        "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2F3b82b3ee-3e0a-11ed-bbc0-00163e010ccd.doc?demo=1",
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Luke Adler",
      type: 1,
      content: "Is it really? You need to buy so many things for Xmas?",
      date: 1663118520,
      avatar_url: require("../../../assets/chat/demo_man.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content:
        "Yep. I have 3 kids. You know heir gifts, ornaments and other stuff need to buy each year.",
      date: 1663118580,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Luke Adler",
      type: 1,
      content: "That's a lot of expense. I'd better save money from now on.",
      date: 1663118640,
      avatar_url: require("../../../assets/chat/demo_man.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content: "hahaha. couldn't agree more!",
      date: 1663118700,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
  ],
  woman: [
    {
      send_side: 2,
      sender: "Hailey Levine",
      type: 1,
      content: "Wanna go to the party tonight?",
      date: 1662812280,
      avatar_url: require("../../../assets/chat/demo_woman.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content: "Yeah...but i am grounded.😭",
      date: 1662812340,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Hailey Levine",
      type: 1,
      content:
        "No worry, just slip out of the window and I will drive you back.",
      date: 1662812400,
      avatar_url: require("../../../assets/chat/demo_woman.svg"),
    },
    {
      send_side: 2,
      sender: "Hailey Levine",
      type: 1,
      content: "BTW, Wyatt will come.",
      date: 1662812460,
      avatar_url: require("../../../assets/chat/demo_woman.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content: "Really? I will go definitely.",
      date: 1662812520,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Hailey Levine",
      type: 1,
      content: "Ok, see ya then.",
      date: 1662812580,
      avatar_url: require("../../../assets/chat/demo_woman.svg"),
    },
    {
      send_side: 3,
      type: 1,
      content: "2022-09-10 02:05",
      date: 1662746700,
    },
    {
      send_side: 1,
      type: 1,
      content: "THX for driving me back. That's a crazy party.",
      date: 1662746700,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Hailey Levine",
      type: 1,
      content: "Yeah, who said no. hahahaha😀",
      date: 1662746760,
      avatar_url: require("../../../assets/chat/demo_woman.svg"),
    },
    {
      send_side: 2,
      sender: "Hailey Levine",
      type: 1,
      content: "I took a pic of Wyatt. He's so hot. ",
      date: 1662746820,
      avatar_url: require("../../../assets/chat/demo_woman.svg"),
    },
    {
      send_side: 2,
      type: 2,
      sender: "Hailey Levine",
      // content: "[picture]",
      date: 1662746880,
      oss_key: "demo/6118da82-49f8-11ed-aec7-00163e010ccd.jpeg",
      file_name: "image.jpeg",
      size: 3907791,
      file_url:
        "https://spy-whatsapp-test.oss-cn-hongkong.aliyuncs.com/demo%2F6118da82-49f8-11ed-aec7-00163e010ccd.jpeg?demo=1",
      avatar_url: require("../../../assets/chat/demo_woman.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content: "Couldn't agree more!  I got his WhatsApp.",
      date: 1662746940,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Hailey Levine",
      type: 1,
      content: "That's great.",
      date: 1662747000,
      avatar_url: require("../../../assets/chat/demo_woman.svg"),
    },
    {
      send_side: 1,
      type: 1,
      content: "Talk to you tomorrow. Its too late. I gotta sleep. ",
      date: 1662747060,
      avatar_url: require("../../../assets/chat/group_man1.svg"),
    },
    {
      send_side: 2,
      sender: "Hailey Levine",
      type: 1,
      content: "Ok. Nighty night.",
      date: 1662747120,
      avatar_url: require("../../../assets/chat/demo_woman.svg"),
    },
  ],
};

export const chatContentRes = {
  data: {
    code: 200,
    message: "OK",
    data: {
      total: 23,
      // messages: chatContentList.group,
      // messages: chatContentList.man,
      messages: chatContentList,
    },
  },
};
